import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const BicolorBackgroundBox = ({ children, ...props }) => {
  const customBoxSx = useCallback((theme) => ({
    background: `linear-gradient(${theme.palette.text.main} 240px, ${theme.palette.background.dark} 240px)`,
  }));
  return (
    <Box
      sx={customBoxSx}
      px="8%"
      py="2%"
      {...props}
    >
      {children}
    </Box>
  );
};

export default BicolorBackgroundBox;

BicolorBackgroundBox.propTypes = {
  children: PropTypes.node.isRequired,
};
